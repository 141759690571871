import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { FaFacebookSquare, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Link from './link';
import styled from "styled-components"
import {SiteConfig} from '../data/siteConfig'
import {GatsbyImage} from 'gatsby-plugin-image'

const FooterStyling = styled.div`
  padding: 2rem 0;
  background: ${props => props.theme.footer.background};
  color: ${props => props.theme.footer.color};
  a, a:hover {
    color: inherit;
  }
  ul {
    color: ${props => props.theme.footer.color};
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      a {
        color: inherit;
      }
    }
  }
  h5 {
    font-size: 17px;
  }
`

let SocialLinkStyling = styled.div`
  display: inline-block;
  &:hover {
    svg {
      color: ${props => props.theme.primary};
    }
  }
`

let FooterText = styled.div`
  background: ${props => props.theme.primary};
  color: white;
`

let SocialLink = ({Icon, to = "/"}) => (
  <SocialLinkStyling>
    <Link to={to} className="mr-3">
      <Icon size={30}/>
    </Link>
  </SocialLinkStyling>
)

let FooterLink = ({to, children}) => (
  <li>
    <Link to={to}>
      {children}
    </Link>
  </li>
)

let Footer = () => {
  let {
    _rawFooterNavigation,
    facebookPath,
    instagramUsername,
    linkedinPath,
    description,
    footerText,
    footerImage,
    title
  } = SiteConfig()
  return (
    <footer>
      {footerText && <FooterText>
        <Container className="py-3">
          {footerText}
        </Container>
      </FooterText>}
      <FooterStyling>
        <Container>
          <Row>
            <Col xs={12} lg={6} xl={5} className="mb-4">
              <GatsbyImage image={footerImage?.asset?.gatsbyImageData} alt={title} className="img-fluid mb-2"/>
              <p className="mb-4">{description}</p>
              {facebookPath ? <SocialLink Icon={FaFacebookSquare} to={facebookPath}/> : ''}
              {instagramUsername ? <SocialLink Icon={FaInstagram} to={'https://www.instagram.com/' + instagramUsername}/> : ''}
              {linkedinPath ? <SocialLink Icon={FaLinkedin} to={instagramUsername}/> : ''}
            </Col>
            <Col lg={6} xl={7}>
              <Row>
                {_rawFooterNavigation && _rawFooterNavigation.map((item, i) => (
                  <Col xs={12} md={4} key={i}>
                    <h5><Link to={item.pageRoute ? '/'+item.pageRoute.slug.current : item.url}>{item.name}</Link></h5>
                    <ul>
                      {item.dropdownItems && item.dropdownItems.map((dropdownItem, i) => (
                        <FooterLink to={dropdownItem.pageRoute ? '/'+dropdownItem.pageRoute.slug.current : dropdownItem.url} key={i}>
                          {dropdownItem.name ? dropdownItem.name : dropdownItem.pageRoute.title}
                        </FooterLink>
                      ))}
                    </ul>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </FooterStyling>
    </footer>
  )
}

export default Footer
