import React from 'react'
import { createGlobalStyle } from 'styled-components'

import hkBoldWoff2 from '../../assets/fonts/HKGrotesk-Bold.woff2'
import hkBoldWoff from '../../assets/fonts/HKGrotesk-Bold.woff'
import hkExtraBoldWoff2 from '../../assets/fonts/HKGrotesk-ExtraBold.woff2'
import hkExtraBoldWoff from '../../assets/fonts/HKGrotesk-ExtraBold.woff'

const HkGrotesqueStyle = createGlobalStyle`
  @font-face {
    font-family: "HKGrotesk";
    font-style: normal;
    font-weight: 300;
    src: url(${hkBoldWoff2}) format("woff2"), url(${hkBoldWoff}) format("woff");;
    font-display: swap;
  }

  @font-face {
    font-family: "HKGrotesk";
    font-style: normal;
    font-weight: 500;
    src: url(${hkExtraBoldWoff2}) format("woff2"), url(${hkExtraBoldWoff}) format("woff");;
    font-display: swap;
  }

  body {
    font-family: 'HKGrotesk', sans-serif;
    overflow-x: hidden;
  }

  h1, .h1 {
    font-family: "HKGrotesk";
    font-weight: 500
  }

  p {
    font-family: 'Roboto', sans-serif;
    .text-muted {
      color: #869ab8;
    }
  }

  strong {
    font-weight: 500;
  }

  .navbar .navbar-nav .nav-item a.nav-link {
    font-size: 1.2rem;
  }
`

const OpenSansStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
  h1, .h1 {
    font-family: 'PT Serif', serif;
    font-weight: 400;
    font-style: normal;
  }
  h2, .h2, h3, .h3 {
    font-family: 'PT Serif', serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.05em;
  }
  h4, .h4 {
    font-family: 'Open Sans', sans-serif;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.1em;
  }
  li.nav-item > a.nav-link {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #4C4D4F;
    font-size: 0.9rem;
  }
`

let HkGrotesque = () => (
  <>
    <HkGrotesqueStyle/>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=PT+Serif:wght@400;700&display=swap" rel="stylesheet"/>
  </>
)

let OpenSans = () => (
  <>
    <OpenSansStyle/>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet"/>
  </>
)

export {HkGrotesque, OpenSans}
