

function theme(variables) {

  function findVar(variable, default_value) {
    let value = variables.filter(v => v.name === variable)[0]
    if (value) {
      return value.value
    } else {
        return default_value
    }
  }

  return ({
    primary: findVar('primary', '#4C4D4F'),
    primaryFaded: findVar('primaryFaded', '#4C4D4F'),
    secondary: '#4C4D4F',
    borderRadius: '0',
    footer: {
      background: findVar('footer.background', '#4C4D4F'),
      color: findVar('footer.color', 'white'),
    },
    page: {
      header: {
        height: findVar('page.header.height', '600px'),
        position: findVar('page.header.position', 'relative'),
      }
    },
    breakpoints: {
      sm: `(min-width: 576px)`,
      md: `(min-width: 768px)`,
      mdOnly: `(min-width: 768px) and (max-width: 992px)`,
      lg: `(min-width: 992px)`,
      xl: `(min-width: 1200px)`,
    },
  })
}

export default theme
