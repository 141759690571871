import React from 'react';
import {
  Container,
  Navbar,
} from 'reactstrap';
import Link from '../link'
import styled from 'styled-components'
import {SiteConfig} from '../../data/siteConfig'
import HeaderIcons from './headerIcons'

let StyledNavbar = styled(props => <Navbar {...props}/>)`
  top: 0;
  z-index: 10;
  .mainLogo {
    width: 200px;
    @media ${props => props.theme.breakpoints.xl} {
      width: 250px;
    }
  }
  .navbar-nav {
    .nav-item > .nav-link {
      &.active, &:hover {
        color: ${props => props.theme.primary}
      }
    }
  }
`

const Header = (props) => {
  let {logo} = SiteConfig()
  return (
    <StyledNavbar color="light" light expand="lg">
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={logo?.asset.url} className="mainLogo" alt="Logo"/>
        </Link>
        <HeaderIcons/>
      </Container>
    </StyledNavbar>
  );
}

export default Header;
