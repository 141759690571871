import React from "react"
import PropTypes from "prop-types"

import HeaderWithoutNav from "./header/headerWithoutNav"
import Header from "./header/header"
import Navbar from "./header/navbar"
import Footer from "./footer"
import Banner from './banner'
import SubFooter from "./subFooter"
import "../assets/stylesheets/layout.scss"
import styled, {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import {SiteConfig} from '../data/siteConfig'
import {HkGrotesque, OpenSans} from './fonts/fonts'
import Helmet from 'react-helmet'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

let fonts = {
  hkGrotesque: HkGrotesque,
  openSans: OpenSans
}

let Base = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
  }
`

Bugsnag.start({
  apiKey: '79b14041bfea7e7b8f62da3dfc587ccd',
  plugins: [new BugsnagPluginReact()],
  notifyReleaseStages: ['production'],
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const Layout = ({ children, hideHeaderAndFooter }) => {
  let {title, baseFont, favicon, layout, variables} = SiteConfig()
  let Font = fonts[baseFont] || HkGrotesque
  return (
    <ThemeProvider theme={theme(variables)}>
      <ErrorBoundary>
        <Base>
          <Helmet>
            {favicon && <link rel="icon" type="image/png" href={favicon.asset.url} sizes="16x16"/>}
          </Helmet>
          <Font/>
          {layout.map(component => {
            switch(component.component) {
              case "banner":
                return hideHeaderAndFooter ? <></> : <Banner key={component._key}/>
              case "header":
                return hideHeaderAndFooter ? <></> : <Header key={component._key}/>
              case "navbar":
                return hideHeaderAndFooter ? <></> : <Navbar key={component._key}/>
              case "headerWithoutNav":
                return hideHeaderAndFooter ? <></> : <HeaderWithoutNav key={component._key}/>
              case "children":
                return <main key={component._key}>{children}</main>
              case "footer":
                return hideHeaderAndFooter ? <></> : <Footer key={component._key}/>
              case "subFooter":
                return <SubFooter title={title} key={component._key}/>
            }
          })}
        </Base>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
