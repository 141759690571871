import React from 'react'
import Link from './link'
import styled from 'styled-components'
import FormattedNumber from './utils/formattedNumber'

let StyledLink = styled(props => <Link {...props}/>)`
  color: ${props => props.theme.primary};
  &:hover {
    color: ${props => props.theme.primary};
  }
`

const HeaderItem = ({text, title, Icon, url, klass, type}) => (
  <div className={klass + ' align-items-center flex mr-3'}>
    <Icon className="mr-2"/>
    <span>
      {title ? title + ': ' : ''}
      {url ? (
        <StyledLink to={url}>
          {type === 'address' ? text : <FormattedNumber number={text}/>}
        </StyledLink>
      ) : (
        text
      )}
    </span>
  </div>
)

const IconLink = ({ href, Icon }) => (
  <Link to={href} className="flex items-center">
    <Icon size='1.2em' className="mr-2"/>
  </Link>
)

export { HeaderItem, IconLink }
