import React, { useState } from 'react';
import Navigation from './jsonNav'
import {SiteConfig} from '../../data/siteConfig'
import Link from '../link'
import {Container, Navbar, Collapse} from 'reactstrap'
import styled from 'styled-components'
import {FaBars} from 'react-icons/fa'

let Background = styled.div`
  background: ${props => props.theme.primary};
  ul.navbar-nav li.nav-item a.nav-link {
    color: white;
    font-size: 1rem;
  }
  .navbar-toggler {
    border-color: white;
  }
`

let NavbarToggler = styled.div`
  cursor: pointer;
`

const Nav = (props) => {
  let {_rawMainNavigation} = SiteConfig()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Background>
      <Navbar color="faded" light expand="lg">
        <Container>
          <NavbarToggler className="border rounded d-lg-none p-2 d-flex align-items-center" onClick={toggle}>
            <FaBars color="white" size={25}/>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Navigation
              json={_rawMainNavigation}
              Link={Link}
              activeClassName="active"
              className="mr-auto"
            />
          </Collapse>
        </Container>
      </Navbar>
    </Background>
  )
}

export default Nav
