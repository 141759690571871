import React from 'react';
import { FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import styled from 'styled-components'
import {SiteConfig} from '../../data/siteConfig'
import Link from '../link'

let Circle = styled.div`
  height: 28px;
  width: 28px;
  background: ${props => props.theme.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.4rem;
  transition-duration: 0.5s;
  &:hover {
    background: ${props => props.theme.primaryFaded};
  }
`

let SocialIcon = ({link, Icon}) => (
  <Link to={link}>
    <Circle>
      <Icon color="white" size="15"/>
    </Circle>
  </Link>
)

let HeaderIcons = () => {
  let {facebookPath, instagramUsername, linkedinPath} = SiteConfig()
  return (
    <div className="d-none d-lg-flex">
      {facebookPath ? <SocialIcon Icon={FaFacebookF} link={facebookPath}/> : ''}
      {instagramUsername ? <SocialIcon Icon={FaInstagram} link={instagramUsername}/> : ''}
      {linkedinPath ? <SocialIcon Icon={FaLinkedinIn} link={linkedinPath}/> : ''}
    </div>
  )
}

export default HeaderIcons
