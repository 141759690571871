import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler
} from 'reactstrap';
import Link from '../link'
import Navigation from './jsonNav'
import styled from 'styled-components'
import {SiteConfig} from '../../data/siteConfig'
import HeaderIcons from './headerIcons'

let StyledNavbar = styled(props => <Navbar {...props}/>)`
  position: sticky;
  top: 0;
  z-index: 10;
  .mainLogo {
    width: 200px;
    @media ${props => props.theme.breakpoints.xl} {
      width: 250px;
    }
  }
  .navbar-nav {
    .nav-item > .nav-link {
      &.active, &:hover {
        color: ${props => props.theme.primary}
      }
    }
  }
`

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  let {logo, _rawMainNavigation} = SiteConfig()

  return (
    <StyledNavbar color="light" light expand="lg">
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={logo?.asset.url} className="mainLogo" alt="Logo"/>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Navigation
            json={_rawMainNavigation}
            Link={Link}
            activeClassName="active"
            className="ml-auto mr-2"
          />
          <HeaderIcons/>
        </Collapse>
      </Container>
    </StyledNavbar>
  );
}

export default Header;
