import React, { useState } from 'react';
import {
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown
} from 'reactstrap';
import { navigate } from "gatsby"
import styled from 'styled-components'

let StyledDropdown = styled(props => <Dropdown {...props}/>)`
  .dropdown-menu {
    margin-top: 0;
  }
`

let NormalLink = ({to, children, Link, ...options}) => (
  <a href={to} {...options}>
    {children}
  </a>
)

let LinkElement = ({to, children, Link, dropdownItems, ...options}) => {
  let LinkComponent = Link ? Link : NormalLink
  return <LinkComponent Link={Link} to={to} children={children} {...options}/>
}

let Item = ({url, name, pageRoute, children, Link, ...options}) => (
  <NavItem>
    <LinkElement
      to={pageRoute ? '/'+pageRoute.slug.current : url}
      className="nav-link"
      Link={Link}
      {...options}
    >
      {name ? name : pageRoute.title}
    </LinkElement>
  </NavItem>
)

let DropdownItem = ({name, url, pageRoute, Link, to, ...options}) => (
  <LinkElement to={pageRoute ? '/'+pageRoute.slug.current : url} Link={Link} className="dropdown-item" {...options}>
    {name ? name : pageRoute.title}
  </LinkElement>
)

let NavDropdown = ({dropdownItems, name, url, Link, to, pageRoute, ...options}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const open = () => {
    setDropdownOpen(true)
  }
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const close = () => {
    setDropdownOpen(false);
  }

  const redirect = (e) => {
    e.preventDefault()
    let path = pageRoute ? '/'+pageRoute.slug.current+'/' : url
    return dropdownOpen ? navigate(path) : open
  }

  return (
    <StyledDropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={open}
      onFocus={open}
      onMouseLeave={close}
      onBlur={close}
    >
      <DropdownToggle
        nav
        caret
        onClick={redirect}
      >
        {name}
      </DropdownToggle>
      <DropdownMenu right>
        {dropdownItems.map(item => (
          <DropdownItem
            Link={Link}
            key={item._key}
            {...item}
            {...options}
          />
        ))}
      </DropdownMenu>
    </StyledDropdown>
  )
}

let JsonNav = ({json, Link, className="ml-auto", ...options}) => (
  <Nav className={className} navbar>
    {json && json.map((nav, i) => {
      let Component = nav.dropdownItems && nav.dropdownItems.length > 0 ? NavDropdown : Item
      return <Component {...nav} key={nav._key} Link={Link} {...options}/>
    })}
  </Nav>
)

export default JsonNav
