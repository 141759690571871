import React from 'react'
import {Container} from 'reactstrap'
import {SiteConfig} from '../data/siteConfig'

let SubFooter = ({title}) => {
  let {legalText} = SiteConfig()
  return (
    <div className="bg-light subFooter">
      <Container className="py-1">
        <span className="text-secondary small">Copyright © {new Date().getFullYear()}. {title}. All rights reserved. {legalText}</span>
      </Container>
    </div>
  )
}

export default SubFooter
