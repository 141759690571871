import React from 'react'
import Link from './link'
import styled from 'styled-components'

let Button = styled.div`
  -webkit-appearance: unset !important;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => (props.color === 'primary' ? props.theme.primary : props.theme.secondary)};
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  color: white;
  display: inline-block;
  padding: .5rem 1rem;
  transition-duration: 0.25s;
  font-size: 1rem;
  border: none;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1);
  }
  @media ${props => props.theme.breakpoints.lg} {
    font-size: 1.2rem;
    padding: .8125rem 1.25rem;
  }
`

let Btn = ({to, children, color = 'primary', ...props}) => {
  if (to) {
    return (
      <Link to={to}>
        <Button color={color} {...props}>
          {children}
        </Button>
      </Link>
    )
  }
  return (
    <Button as="button" color={color} {...props}>
      {children}
    </Button>
  )
}

export default Btn
