import React from 'react'
import NumberFormat from 'react-number-format'

const FormattedNumber = ({number}) => {
  if (!number) {
    return <></>
  }
  if (number.startsWith(0)) {
    return <NumberFormat value={number} displayType={'text'} format="(##) #### ####"/>
  }
  return <NumberFormat value={number} displayType={'text'} format="#### ### ###"/>
}

export default FormattedNumber
